:root {
    --bg-color: #F6F8FF;
    --article-bg-color: #FEFEFE;
    --font-primary: #4B6A9B;
    --font-secondary: #2B3442;
    --btn-color: #0079FF;
    --btn-font: #FFFFFF;
    --hover-color: #2B3442;
    --box-shadow: #697C9A30;

}

[data-theme="dark"] {
     --bg-color: #141D2F;
     --article-bg-color: #1E2A47;
     --font-primary: #FFFFFF;
     --font-secondary: #FFFFFF;
     --hover-color: #90A4D4;
     --box-shadow: transparent;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Space Mono', monospace;
}

html {
    height: 100%;
    background-color: var(--bg-color);
}

body {
    min-height: 100%;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.5625rem;

    color: var(--font-primary);
}

.main-container {
    margin: 2rem 1.5rem;
}

body {
    font-size: 0.9375rem;
}

h1 {
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 2.375rem;

    color: var(--font-secondary);
}

h2 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 2.0625rem;

    color: var(--font-secondary);
}

h3 {
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.5rem;
}

h4 {
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 1.25rem;
}

/* tablet styles */
@media only screen and (min-width: 768px) {
    .main-container {
        margin: 8.75rem 6.125rem;
    }

    h2 {
        font-size: 1.625rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4 {
        font-size: 0.8125rem;
    }
}

@media only screen and (min-width: 992px) {

    .main-container {
        margin: 9rem 12rem;
    }
}