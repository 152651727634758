.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.25rem;
}

.theme-container {
    display: flex;
    flex-direction: row;     
}


.theme-label {
    display: flex; 
}

.theme-label, .theme-checkbox {
    cursor: pointer;
}

.theme-checkbox {
    height: 1.25rem;
    width: 1.25rem;
    margin-left: 1rem;
    appearance: none;
    background: url("./../../public/icon-moon.svg") no-repeat;
}

.theme-checkbox:checked {
    background: url("./../../public/icon-sun.svg") no-repeat;
}

/* tablet styles */
@media only screen and (min-width: 768px) {

    .header {
        margin-bottom: 2rem;
    }
}




