.profile {
    display: flex;
    flex-direction: column;
    background-color: var(--article-bg-color);
    padding: .5rem;
    border-radius: 1rem;
    margin: 1rem 0;
    box-shadow: 0 .5rem .5rem 0 var(--box-shadow);
    padding: 2rem 1.5rem 3rem 1.5rem;
}

.profile-banner {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.avatar-image {
    height: 4.375rem;
    width: 4.375rem;
    border-radius: 2rem;
    margin-right: 1.2rem;
}

.tag {
    color: var(--btn-color);
}

.profile-body {
    margin-bottom: 1.4375rem;
}

.profile-stats {
    display: flex;
    justify-content: space-around;
    background-color: var(--bg-color);
    padding: 1.125rem 0.9375rem;
    border-radius: .5rem;
    margin-bottom: 1.5rem;
}

.metric {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.metric-value {
    font-weight: 700;
    align-self: center;
    font-size: 1rem;
}

.profile-socials {
    display: flex;
    flex-direction: column;
}

a {
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 1.25rem;
    color: var(--font-primary);
    text-decoration: none;
}

a:hover {
  text-decoration: underline;

}

.social {
    display: flex;
    flex-direction: row;
}

.img-container {
    width: 1.25rem;
    margin-right: 0.8125rem;
}

.social .map {
    width: 0.859rem;
    height: 1.25rem;
}

.social .website, .company {
    width: 1.25rem;
    height: 1.25rem;
}

.social .twitter {
    width: 1.25rem;
    height: 1rem;
}

.social:not(:last-child) {
    margin-bottom: 1rem;
}

/* tablet styles */
@media only screen and (min-width: 768px) {

    .profile {
        padding: 2.5rem;
    }

    .profile-banner {
        margin-bottom: 1.5rem;
    }

    .avatar-image {
        height: 7.3125rem;
        width: 7.3125rem;
        border-radius: 3.5rem;
        margin-right: 2.5rem;
    }

    .profile-body {
        margin-bottom: 2rem;
    }

    .profile-stats {
        margin-bottom: 1.875rem;
    }

    .profile-socials {
        display: grid;
       grid-template-columns: 50% 50%;
    }

}

@media only screen and (min-width: 992px) {
    .profile-body {
        margin-left: 10rem;
    }

    .profile-stats {
        margin-left: 10rem;
    }

    .profile-socials {
        margin-left: 10rem;
    }
   
}
